import React from "react";
import { ReactElement } from "react";
import { UIKitMode } from "../../../shared/Types";
import { UIKitButton, UIKitText, UIKitTextInput } from "../../../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";
import TextInput from "../../TextInput";
import { HorizontalAlignment } from "../../Container";

const ControlBaseText = (): ReactElement => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <>
      {useUIKitTreeContext.selectedUIKitComponent &&
        useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText && (
          <div className="flex mt-4 flex-col">
            <div className="w-full">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Text Content
              </label>
              <div className="mt-2">
                <input
                  className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}
                  defaultValue={
                    useUIKitTreeContext.selectedUIKitComponent.text
                      ? useUIKitTreeContext.selectedUIKitComponent.text
                      : ""
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent &&
                      useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.text = event.target.value;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row mt-2 space-x-2">
              <input
                type="number"
                className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={useUIKitTreeContext.selectedUIKitComponent.fontSize}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    useUIKitTreeContext.selectedUIKitComponent &&
                    useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText
                  ) {
                    useUIKitTreeContext.selectedUIKitComponent.fontSize = parseInt(event.target.value);
                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              />

              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className={"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" + 
                  (useUIKitTreeContext.selectedUIKitComponent.textAlignment == HorizontalAlignment.LeftAligned ? " bg-gray-100" : "bg-white")}
                  onClick={() => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent &&
                      useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.textAlignment = HorizontalAlignment.LeftAligned;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                >
                  Align Left
                </button>
                <button
                  type="button"
                  className={"relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" + 
                  (useUIKitTreeContext.selectedUIKitComponent.textAlignment == HorizontalAlignment.CenterAligned ? " bg-gray-100" : "bg-white")}
                  onClick={() => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent &&
                      useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.textAlignment = HorizontalAlignment.CenterAligned;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                >
                  Align Center
                </button>
                <button
                  type="button"
                  className={"relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" + 
                  (useUIKitTreeContext.selectedUIKitComponent.textAlignment == HorizontalAlignment.RightAligned ? " bg-gray-100" : "bg-white")}
                  onClick={() => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent &&
                      useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.textAlignment = HorizontalAlignment.RightAligned;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                >
                  Align Right
                </button>
              </span>
            </div>
          </div>
        )}

        {useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitTextInput && (
                    <div className="flex mt-4 flex-row gap-x-4 items-center">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          Multi Line Input:
                        </label>

                        <input
          className="form-checkbox h-5 w-5 text-blue-500"
          type="checkbox"
          checked={useUIKitTreeContext.selectedUIKitComponent &&
            useUIKitTreeContext.selectedUIKitComponent.multiLine}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
            if (useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitTextInput) {
              useUIKitTreeContext.selectedUIKitComponent.multiLine = event.target.checked;
              useUIKitTreeContext.updateUIKitTreeState();
              useUIKitTreeContext.updateComponentTree();
            }
          }}
        />
                      </div>
        )}
    </>
  );
};

export default ControlBaseText;

import React, { createContext, useCallback, useEffect, useState } from "react";
import { VerticalAlignment, HorizontalAlignment, UIKitContainerLayoutType } from "./components/Container";
import { UIProps } from "./shared/Types";
import fetch from "node-fetch";
import {
  UIKitAppViewContainer,
  UIKitButton,
  UIKitComponent,
  UIKitComponentType,
  UIKitContainer,
  UIKitText,
  UIKitTextInput,
  ChatComponent,
  UIKitComponentParams,
  PromptBlockAction,
} from "./shared/UIKitComponent";
import { AppEnvironment } from "./utilities/AppEnvironment/Types";
import { useQuery, useMutation } from "@tanstack/react-query";
import { PromptBlock } from "./DataEditor";

export interface IUIKitTreeContext {
  UIKitTree: UIKitAppViewContainer | undefined;
  selectedUIKitComponent?: UIKitComponent | undefined;
  updateUIKitTreeState: () => void;
  setSelectedUIKitComponentState: (component: UIKitComponent | undefined) => void;
  updateContainer: (componet: UIKitComponent) => void;
  currentModal: ModalType | undefined;
  setCurrentModalState: (modal: ModalType | undefined) => void;
  updateComponentTree: () => void;
  loadAppEnvironmentFromObject: (appEnvironment: AppEnvironment) => void;
  loadAppEnvironmentFromNetwork: (appEnvironmentId: string) => void;
  loadLiveAppEnvironmentFromNetwork: (appEnvironmentId: string) => void;
  appEnvironment?: AppEnvironment;
  generateUserLLMMessage: (interpolatedPrompt: string) => void;
  setCurrentUser: (user: User) => void;
  currentUser?: User;
  isLoggedOut: boolean;
  setIsLoggedOut: (isLoggedOut: boolean) => void;
}

export const UIKitTreeContext = createContext<IUIKitTreeContext>({
  UIKitTree: undefined,
  updateUIKitTreeState: () => {},
  setSelectedUIKitComponentState: () => {},
  selectedUIKitComponent: undefined,
  updateContainer: (componet: UIKitComponent) => {},
  currentModal: undefined,
  setCurrentModalState: (modal: ModalType | undefined) => {},
  updateComponentTree: () => {},
  loadAppEnvironmentFromObject: (appEnvironment: AppEnvironment) => {},
  loadAppEnvironmentFromNetwork: (appEnvironmentId: string) => {},
  loadLiveAppEnvironmentFromNetwork: (appEnvironmentId: string) => {},
  appEnvironment: undefined,
  generateUserLLMMessage: (interpolatedPrompt: string) => {},
  setCurrentUser: (user: User) => {},
  currentUser: { id: "", email: "" },
  isLoggedOut: false,
  setIsLoggedOut: (isLoggedOut: boolean) => {},
});

export enum ModalType {
  AddDatabaseRecordType,
  EditWorkflow,
  AppsListModal,
  AppWizardModal,
  UserFeedbackModal,
  UserFeatureRequestModal,
  ShareAppModal,
}
class Modal {
  type: ModalType;

  constructor(type: ModalType) {
    this.type = type;
  }
}

export class EditWorkflowModal extends Modal {
  workflowName?: string;

  constructor(workflowName?: string) {
    super(ModalType.EditWorkflow);
    this.workflowName = workflowName;
  }
}

export type User = {
  id: string;
  email: string;
};

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export const UIKitTreeProvider = (props: UIProps) => {
  const [appEnvironment, setAppEnvironment] = React.useState<AppEnvironment>();
  const [UIKitTree, setUIKitTree] = React.useState<UIKitContainer>();
  const [selectedUIKitComponent, setSelectedUIKitComponent] = React.useState<UIKitComponent>();
  const [currentModal, setCurrentModal] = React.useState<ModalType>();
  const [currentAppEnvironmentId, setCurrentAppEnvironmentId] = useState<string>();
  const [promptBlock, setPromptBlock] = useState<PromptBlock>();
  const [currentUser, setCurrentUser] = useState<User>();
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
  const [liveAppEnvironmentURL, setLiveAppEnvironmentURL] = useState<string>();

  interface userLLMMessageVariables {
    interpolatedPrompt: string;
  }

  const createUserLlmMessage = async ({ interpolatedPrompt }: userLLMMessageVariables) => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/create_llm_message_for_user_app`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
        Accept: "application/json",
      },
      body: JSON.stringify({ content: interpolatedPrompt }),
    });

    if (!response.ok && response.status == 401) {
      // Logout the user if they're unauthorized
      setIsLoggedOut(true);
    }

    return await response.json();
  };

  let createUserLlmMessageMutation = useMutation({
    mutationFn: createUserLlmMessage,
    onSuccess: () => {},
  });

  const generateUserLLMMessage = (interpolatedPrompt: string) => {
    createUserLlmMessageMutation.mutate({ interpolatedPrompt: interpolatedPrompt });
  };
  const updateComponentTreeRequest = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/app_environment/${currentAppEnvironmentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
        Accept: "application/json",
      },
      body: JSON.stringify({ component_tree: UIKitTree?.toJSONDocument() }),
    });

    if (!response.ok && response.status == 401) {
      // Logout the user if they're unauthorized
      setIsLoggedOut(true);
    }

    return await response.json();
  };

  const updateComponentTreeMutation = useMutation({ mutationFn: updateComponentTreeRequest });

  const updateComponentTree = async () => {
    updateComponentTreeMutation.mutate();
  };

  const getWebAApplicationQuery = useQuery({
    queryKey: ["appEnvironment", currentAppEnvironmentId],
    queryFn: async () => {
      let data;
      const response = await fetch(`${API_ENDPOINT_URL}/api/app_environment/${currentAppEnvironmentId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token") ?? "",
          Accept: "application/json",
        },
      });

      if (!response.ok && response.status == 401) {
        // Logout the user if they're unauthorized
        setIsLoggedOut(true);
      }

      data = await response.json();
      return data;
    },
    enabled: currentAppEnvironmentId !== undefined,
  });

  const getPromptBlockQuery = useQuery({
    queryKey: ["promptBlock", currentAppEnvironmentId],
    queryFn: async () => {
      const response = await fetch(`${API_ENDPOINT_URL}/api/prompt_block/${currentAppEnvironmentId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token") ?? "",
          Accept: "application/json",
        },
      });

      if (!response.ok && response.status == 401) {
        // Logout the user if they're unauthorized
        setIsLoggedOut(true);
      }

      const data = await response.json();
      return data;
    },
    enabled: currentAppEnvironmentId !== undefined,
  });

  const getLiveAppEnvironmentQuery = useQuery({
    queryKey: ["appEnvironment", liveAppEnvironmentURL],
    queryFn: async () => {
      let data;
      const response = await fetch(`${API_ENDPOINT_URL}/api/app_enviroment/live_version/${liveAppEnvironmentURL}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token") ?? "",
          Accept: "application/json",
        },
      });

      data = await response.json();
      return data;
    },
    enabled: liveAppEnvironmentURL !== undefined,
  });

  const updateUIKitTreeState = (): void => {
    let updatedRootContainer: UIKitAppViewContainer | undefined = undefined;
    if (UIKitTree) {
      updatedRootContainer = new UIKitAppViewContainer(
        {
          type: UIKitTree.type,
          id: UIKitTree.id,
          name: UIKitTree.name,
          children: UIKitTree.children,
          verticalAlign: UIKitTree.verticalAlign,
          horizontalAlign: UIKitTree.horizontalAlign,
          disableDragOverlay: UIKitTree.disableDragOverlay,
          width: UIKitTree.width,
          height: UIKitTree.height,
          x: UIKitTree.x,
          y: UIKitTree.y,
          canDrag: UIKitTree.canDrag,
          fixedWidth: UIKitTree.fixedWidth,
          fixedHeight: UIKitTree.fixedHeight,
          fitWidthToContent: UIKitTree.fitWidthToContent,
          fitHeightToContent: UIKitTree.fitHeightToContent,
          fillHeightToParent: UIKitTree.fillHeightToParent,
          fillWidthToParent: UIKitTree.fillWidthToParent,
        },
        UIKitTree.layoutType,
        UIKitTree.rowLayoutType,
        UIKitTree.columnLayoutType
      );

      setUIKitTree(updatedRootContainer);
    }
  };

  const loadUIKitTreeFromJSON = (plainUIKitComponent: UIKitComponent): UIKitComponent => {
    let params: UIKitComponentParams = {
      type: plainUIKitComponent.type,
      id: plainUIKitComponent.id,
      name: plainUIKitComponent.name,
      children: [],
      parent: undefined,
      verticalAlign: plainUIKitComponent.verticalAlign,
      horizontalAlign: plainUIKitComponent.horizontalAlign,
      width: plainUIKitComponent.width,
      height: plainUIKitComponent.height,
      x: plainUIKitComponent.x,
      y: plainUIKitComponent.y,
      disableDragOverlay: plainUIKitComponent.disableDragOverlay,
      canDrag: plainUIKitComponent.canDrag,
      canResize: plainUIKitComponent.canResize,
      fixedWidth: plainUIKitComponent.fixedWidth,
      fixedHeight: plainUIKitComponent.fixedHeight,
      fitWidthToContent: plainUIKitComponent.fitWidthToContent,
      fitHeightToContent: plainUIKitComponent.fitHeightToContent,
      minWidth: plainUIKitComponent.minWidth,
      minHeight: plainUIKitComponent.minHeight,
      maxWidth: plainUIKitComponent.maxWidth,
      maxHeight: plainUIKitComponent.maxHeight,
      event: plainUIKitComponent.event,
      fillHeightToParent: plainUIKitComponent.fillHeightToParent,
      fillWidthToParent: plainUIKitComponent.fillWidthToParent,
    };

    let initializedUIKitComponent: UIKitComponent;

    switch (plainUIKitComponent.type) {
      case UIKitComponentType.AppView:
        const plainAppViewContainer = plainUIKitComponent as UIKitAppViewContainer;
        initializedUIKitComponent = new UIKitAppViewContainer(
          params,
          plainAppViewContainer.layoutType,
          plainAppViewContainer.rowLayoutType,
          plainAppViewContainer.columnLayoutType
        );
        (initializedUIKitComponent as UIKitAppViewContainer).flexGap = plainAppViewContainer.flexGap;

        (initializedUIKitComponent as UIKitAppViewContainer).paddingTop = plainAppViewContainer.paddingTop;
        (initializedUIKitComponent as UIKitAppViewContainer).paddingBottom = plainAppViewContainer.paddingBottom;
        (initializedUIKitComponent as UIKitAppViewContainer).paddingLeft = plainAppViewContainer.paddingLeft;
        (initializedUIKitComponent as UIKitAppViewContainer).paddingRight = plainAppViewContainer.paddingRight;
        break;

      case UIKitComponentType.Container:
        const plainContainer = plainUIKitComponent as UIKitContainer;
        initializedUIKitComponent = new UIKitContainer(
          params,
          plainContainer.layoutType,
          plainContainer.rowLayoutType,
          plainContainer.columnLayoutType
        );

        (initializedUIKitComponent as UIKitAppViewContainer).flexGap = plainContainer.flexGap;

        (initializedUIKitComponent as UIKitAppViewContainer).paddingTop = plainContainer.paddingTop;
        (initializedUIKitComponent as UIKitAppViewContainer).paddingBottom = plainContainer.paddingBottom;
        (initializedUIKitComponent as UIKitAppViewContainer).paddingLeft = plainContainer.paddingLeft;
        (initializedUIKitComponent as UIKitAppViewContainer).paddingRight = plainContainer.paddingRight;
        break;

      case UIKitComponentType.Button:
        let promptAction: PromptBlockAction | undefined = undefined;

        if (promptBlock?.triggerComponentId === plainUIKitComponent.id) {
          promptAction = new PromptBlockAction(
            promptBlock.id,
            promptBlock.promptValue,
            promptBlock.outputComponentId,
            promptBlock.triggerComponentId
          );
        }
        const plainButton = plainUIKitComponent as UIKitButton;

        initializedUIKitComponent = new UIKitButton(params, plainButton.text, plainButton.fontSize);
        if (promptAction) {
          (initializedUIKitComponent as UIKitButton).promptBlockAction = promptAction;
        }
        break;

      case UIKitComponentType.Text:
        const plainText = plainUIKitComponent as UIKitText;
        initializedUIKitComponent = new UIKitText(params, plainText.text, plainText.fontSize, plainText.textAlignment);
        break;

      case UIKitComponentType.TextInput:
        const plainTextInput = plainUIKitComponent as UIKitTextInput;
        initializedUIKitComponent = new UIKitTextInput(
          params,
          plainTextInput.text,
          plainTextInput.placeholder,
          plainTextInput.multiLine
        );
        break;
      case UIKitComponentType.Chat:
        initializedUIKitComponent = new ChatComponent(params);
        break;
      case UIKitComponentType.List:
        initializedUIKitComponent = new ChatComponent(params);
        break;
    }

    if (plainUIKitComponent.children) {
      initializedUIKitComponent.children = plainUIKitComponent.children.map((child: any) => {
        const initializedChildUIKitComponent = loadUIKitTreeFromJSON(child);
        initializedChildUIKitComponent.parent = initializedUIKitComponent;

        return initializedChildUIKitComponent;
      });
    }

    return initializedUIKitComponent;
  };

  useEffect(() => {
    // Set the token for the current user if there is a token in local storage
    let user: User = {
      id: localStorage.getItem("userId") ?? "",
      email: localStorage.getItem("userEmail") ?? "",
    };

    setCurrentUser(user);
  }, []);

  useEffect(() => {
    if (currentAppEnvironmentId) {
      localStorage.setItem("lastOpenedAppEnvironmentId", currentAppEnvironmentId);
    }
  }, [currentAppEnvironmentId]);

  useEffect(() => {
    if (getPromptBlockQuery.isFetched && getPromptBlockQuery.data && !promptBlock) {
      const parsedJSONPromptBlock = getPromptBlockQuery.data;
      setPromptBlock({
        id: parsedJSONPromptBlock.id,
        outputComponentId: parsedJSONPromptBlock.outputComponentId,
        triggerComponentId: parsedJSONPromptBlock.trigger_component_id,
        promptValue: parsedJSONPromptBlock.prompt,
      });
    }

    if (getWebAApplicationQuery.isFetched && getWebAApplicationQuery.data && !appEnvironment) {
      const parsedJSONWebApplication = getWebAApplicationQuery.data;

      let appEnvironment: AppEnvironment = new AppEnvironment(
        parsedJSONWebApplication.id,
        parsedJSONWebApplication.name
      );

      if (parsedJSONWebApplication.live_url && parsedJSONWebApplication.live_url.length > 0) {
        appEnvironment.liveURL = parsedJSONWebApplication.live_url;
      }

      appEnvironment.componentTree = parsedJSONWebApplication.component_tree as UIKitAppViewContainer;
      setAppEnvironment(appEnvironment);
    }
  }, [getWebAApplicationQuery.isFetched, promptBlock, getPromptBlockQuery.isFetched, getPromptBlockQuery.status]);

  useEffect(() => {
    if (getLiveAppEnvironmentQuery.isFetched && getLiveAppEnvironmentQuery.data) {
      const parsedJSONWebApplication = getLiveAppEnvironmentQuery.data;

      let appEnvironment: AppEnvironment = new AppEnvironment(
        parsedJSONWebApplication.app_enviroment.id,
        parsedJSONWebApplication.app_enviroment.name
      );

      if (
        parsedJSONWebApplication.app_enviroment.live_url &&
        parsedJSONWebApplication.app_enviroment.live_url.length > 0
      ) {
        appEnvironment.liveURL = parsedJSONWebApplication.live_url;
      }

      let parsedJSONPromptBlock = parsedJSONWebApplication.prompt_block;

      setPromptBlock({
        id: parsedJSONPromptBlock.id,
        outputComponentId: parsedJSONPromptBlock.outputComponentId,
        triggerComponentId: parsedJSONPromptBlock.trigger_component_id,
        promptValue: parsedJSONPromptBlock.prompt,
      });

      appEnvironment.componentTree = parsedJSONWebApplication.app_enviroment.component_tree as UIKitAppViewContainer;
      setAppEnvironment(appEnvironment);
    }
  }, [liveAppEnvironmentURL, getLiveAppEnvironmentQuery.isFetched]);

  useEffect(() => {
    if (appEnvironment && appEnvironment?.componentTree) {
      setUIKitTree(loadUIKitTreeFromJSON(appEnvironment.componentTree) as UIKitAppViewContainer);
    }
  }, [appEnvironment, promptBlock]);

  const setSelectedUIKitComponentState = (component: UIKitComponent | undefined) => {
    setSelectedUIKitComponent(component);
  };

  const setCurrentModalState = (modal: ModalType | undefined) => {
    setCurrentModal(modal);
  };

  // This only exists as when we update certain properties on a container, it may also need to update the root container
  // If the property does not exist on the root container (AppView) then we should use updateUIKitTreeState() instead
  const updateContainer = (updatedComponent: UIKitComponent): void => {
    if (updatedComponent instanceof UIKitAppViewContainer) {
      let updatedContainer = new UIKitAppViewContainer(
        {
          ...updatedComponent,
        },
        updatedComponent.layoutType,
        updatedComponent.rowLayoutType,
        updatedComponent.columnLayoutType
      );

      // If the container is the root of all containers then set the base of the tree to this container
      if (!updatedComponent.parent) {
        updatedComponent.children.forEach((child: UIKitComponent) => {
          child.parent = updatedContainer;
        });
        setUIKitTree(updatedContainer);
      } else {
        updateUIKitTreeState();
      }
    } else if (updatedComponent instanceof UIKitContainer) {
      let updatedContainer = new UIKitContainer(
        {
          ...updatedComponent,
        },
        updatedComponent.layoutType,
        updatedComponent.rowLayoutType,
        updatedComponent.columnLayoutType
      );

      if (!updatedComponent.parent) {
        setUIKitTree(updatedContainer);
      } else {
        updateUIKitTreeState();
      }
    }
  };

  function loadAppEnvironmentFromNetwork(appEnvironmentId: string) {
    setCurrentAppEnvironmentId(appEnvironmentId);
  }

  function loadAppEnvironmentFromObject(appEnvironment: AppEnvironment) {
    setAppEnvironment(appEnvironment);

    if (appEnvironment.componentTree) {
      setUIKitTree(loadUIKitTreeFromJSON(appEnvironment.componentTree) as UIKitAppViewContainer);
    }
  }

  function loadLiveAppEnvironmentFromNetwork(appEnvironmentLiveUrl: string) {
    setLiveAppEnvironmentURL(appEnvironmentLiveUrl);
  }

  const context: IUIKitTreeContext = {
    UIKitTree: UIKitTree,
    selectedUIKitComponent: selectedUIKitComponent,
    updateUIKitTreeState: updateUIKitTreeState,
    setSelectedUIKitComponentState: setSelectedUIKitComponentState,
    updateContainer: updateContainer,
    currentModal: currentModal,
    setCurrentModalState: setCurrentModalState,
    updateComponentTree: updateComponentTree,
    loadAppEnvironmentFromObject: loadAppEnvironmentFromObject,
    loadAppEnvironmentFromNetwork: loadAppEnvironmentFromNetwork,
    appEnvironment: appEnvironment,
    generateUserLLMMessage: generateUserLLMMessage,
    setCurrentUser: setCurrentUser,
    currentUser: currentUser,
    isLoggedOut: isLoggedOut,
    setIsLoggedOut: setIsLoggedOut,
    loadLiveAppEnvironmentFromNetwork: loadLiveAppEnvironmentFromNetwork,
  };

  return <UIKitTreeContext.Provider value={context}>{props.children}</UIKitTreeContext.Provider>;
};

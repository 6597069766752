import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { IUIKitTreeContext, UIKitTreeContext } from "./UIKitTreeContext";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppEnvironment } from "./utilities/AppEnvironment/Types";
import { UIKitAppViewContainer } from "./shared/UIKitComponent";
import { useNavigate } from "react-router-dom";

export type AppListProps = {
  userId: string;
};

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export const AppListModal = (props: AppListProps) => {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  const getAppEnvironmentsForUserQuery = useQuery({
    queryKey: ["appEnvironments", useUIKitTreeContext?.currentUser?.id],
    queryFn: async () => {
      const response = await fetch(
        `${API_ENDPOINT_URL}/api/api/app_environments_for_user/${useUIKitTreeContext?.currentUser?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token") ?? "",
          },
        }
      );

      const data = await response.json();
      return data;
    },
    enabled: !!useUIKitTreeContext?.currentUser,
  });

  const navigation = useNavigate();

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(false);
            useUIKitTreeContext.setCurrentModalState(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-100 bg-opacity-80 backdrop-blur-sm transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-2 sm:mt-0 sm:text-left w-full">
                        <Dialog.Title as="h3" className="text-base font-bold leading-6 text-gray-900">
                          Your Apps
                        </Dialog.Title>

                        {getAppEnvironmentsForUserQuery.isLoading && <div>Loading your projects...</div>}
                        {getAppEnvironmentsForUserQuery.isError && <div>There was an error loading your projects</div>}

                        <div className="w-full p-2 flex flex-col space-y-4">
                          <ul role="list" className="divide-y divide-gray-200 w-full">
                            {getAppEnvironmentsForUserQuery.data &&
                              getAppEnvironmentsForUserQuery.data.map((app_environment_from_database: any) => (
                                <li
                                  key={app_environment_from_database.id}
                                  className="py-4 flex flex-row justify-between items-center"
                                >
                                  <label className="block text-lg font-normal leading-6 text-gray-900">
                                    {app_environment_from_database.name}
                                  </label>
                                  <button
                                    type="submit"
                                    className="flex justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => {
                                      useUIKitTreeContext.setCurrentModalState(undefined);
                                      let appEnvironment: AppEnvironment = new AppEnvironment(
                                        app_environment_from_database.id,
                                        app_environment_from_database.name
                                      );

                                      appEnvironment.componentTree =
                                        app_environment_from_database.component_tree as UIKitAppViewContainer;

                                      useUIKitTreeContext.loadAppEnvironmentFromObject(appEnvironment);
                                      navigation(`/editor/builder/${appEnvironment.id}`);
                                    }}
                                  >
                                    Open in editor
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const AppWizardModal = () => {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("");
  const navigation = useNavigate();
  const [appCreationComplete, setAppCreationComplete] = useState(false);

  const useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  const createAppEnvironment = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/app_environment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
      },
      body: JSON.stringify({ user_email: useUIKitTreeContext?.currentUser?.email, name: name, prompt: prompt }),
    });

    return await response.json();
  };

  const createAppEnvironmentMutation = useMutation({
    mutationFn: createAppEnvironment,
    onSuccess: (response) => {
      let appEnvironment: AppEnvironment = new AppEnvironment(response.id, response.name);

      appEnvironment.componentTree = response.component_tree as UIKitAppViewContainer;

      useUIKitTreeContext.loadAppEnvironmentFromObject(appEnvironment);
      navigation(`/editor/builder/${appEnvironment.id}`);
      setAppCreationComplete(true);
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-100 bg-opacity-80 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl">
                {appCreationComplete && (
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-2 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                          ✨ Your new app has been created! ✨
                        </Dialog.Title>
                      </div>
                    </div>

                    <div className="w-full p-2 flex flex-col space-y-4 justify-between">
                      <div className="w-full mt-2">
                        <div className="text-md font-normal leading-6 text-gray-900">
                          <label className="text-md font-bold"> Next Steps:</label>
                          <br></br>
                          <b>1. </b>Tweak the AI generated elements in the editor using drag and drop, double click on a
                          element to customise them further.
                          <br></br>
                          <b>2. </b>Setup your chat gpt prompt to the app by clicking on the `Prompt Builder` tab at the
                          top of the editor. You can add user input from your app and setup a trigger for the prompt
                          (i.e. from a button).
                          <br></br>
                          <b>3. </b>Click on the `Share Your App` to share your creation with the world. You will be
                          given a unique URL that you can share with anyone.
                        </div>
                        <div className="flex flex-row w-full justify-center gap-x-2 mt-4">
                          <button
                            type="button"
                            className="w-24 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              setOpen(false);
                              setAppCreationComplete(false);
                              useUIKitTreeContext.setCurrentModalState(undefined);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!appCreationComplete && (
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-2 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-bold leading-6 text-gray-900">
                          Create a new App
                        </Dialog.Title>
                      </div>
                    </div>

                    <div className="w-full p-2 flex flex-col space-y-4 justify-between">
                      <div className="flex mt-2 w-full">
                        <div className="w-full">
                          <label className="block text-md font-normal leading-6 text-gray-900">App Name</label>
                          <div className="flex mt-2 w-full">
                            <input
                              type="text"
                              className="w-full mb-4 rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
                              onChange={(e) => setName(e.target.value)}
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="w-full">
                        <label className="block text-md font-normal leading-6 text-gray-900">
                          ✨ Describe what you want your app to do? ✨
                        </label>
                        <div className="flex mt-2 w-full">
                          <textarea
                            rows={8}
                            className="w-full mb-4 rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
                            onChange={(e) => setPrompt(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="flex flex-row w-full justify-end gap-x-2">
                        <button
                          type="button"
                          className="w-18 inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            setOpen(false);
                            useUIKitTreeContext.setCurrentModalState(undefined);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="w-18 inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            createAppEnvironmentMutation.mutate();
                          }}
                        >
                          {createAppEnvironmentMutation.isPending && (
                            <>
                              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <label>Generating</label>
                            </>
                          )}

                          {createAppEnvironmentMutation.isIdle && (
                            <>
                              <label>Generate</label>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

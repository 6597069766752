import { ReactElement } from "react"
import { TextProps, UIKitMode } from "../shared/Types"

const Text = (props: TextProps): ReactElement => { 
    return ( 
        <div id={props.id}
        style={{...props.mode == UIKitMode.Edit ? { width: '100%', height: '100%', pointerEvents: "none" } : {...props.style}}}
        >{props.text}</div>
    )
}

export default Text;
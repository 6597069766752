import React from "react";
import { ReactNode, useEffect, useState } from "react";
import ControlBaseColumnItemHorizontalAlignment from "./components/Control/Base/ColumnItemHorizontalAlignment";
import ControlBaseHeight from "./components/Control/Base/Height";
import ControlBaseOrder from "./components/Control/Base/Order";
import ControlBaseRowItemVerticalAlignment from "./components/Control/Base/RowItemVerticalAlignment";
import ControlBaseText from "./components/Control/Base/Text";
import ControlBaseWidth from "./components/Control/Base/Width";
import ControlContainerColumnAlignment from "./components/Control/Container/Column";
import ControlContainerLayoutType from "./components/Control/Container/LayoutType";
import ControlContainerRowAlignment from "./components/Control/Container/Row";
import { UIKitComponent, UIKitComponentType, UIKitContainer } from "./shared/UIKitComponent";
import ControlComponentName from "./components/Control/Base/ComponentName";
import { IUIKitTreeContext, UIKitTreeContext } from "./UIKitTreeContext";

interface ComponentPropertiesProps {
  selectedUIKitComponent: UIKitComponent | undefined;
}

enum ContainerProperties {
  LayoutType,
  RowLayoutType,
  ColumnLayoutType,
}

enum ButtonProperties {
  Text,
}

enum TextProperties {
  Text,
}

const GoToParentContainerLink = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <>
      {" "}
      {useUIKitTreeContext.selectedUIKitComponent?.parent !== undefined && (
        <div className="w-full mt-4">
          <div className="flex flex-col">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-black mb-2">
              Parent Container
            </label>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              onClick={() => {
                if (useUIKitTreeContext.selectedUIKitComponent) {
                  useUIKitTreeContext.setSelectedUIKitComponentState(
                    useUIKitTreeContext.selectedUIKitComponent?.parent
                  );
                }
              }}
            >
              {useUIKitTreeContext.selectedUIKitComponent?.parent?.name} (Click to open)
            </button>
          </div>

          <div className="flex mt-2">
            <span className="isolate inline-flex rounded-md shadow-sm"></span>
          </div>
        </div>
      )}
    </>
  );
};

const ContainerGapAndPaddingProperties = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <>
      <div className="flex mt-4 flex-row space-x-4">
        <div className="flex flex-col">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Gap
          </label>

          <div className="flex flex-row mt-2 space-x-2">
            <input
              type="number"
              className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={useUIKitTreeContext.selectedUIKitComponent?.asUIKitContainer()?.flexGap ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  useUIKitTreeContext.selectedUIKitComponent &&
                  useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                ) {
                  useUIKitTreeContext.selectedUIKitComponent.flexGap = parseInt(event.target.value);
                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();

                  console.log(useUIKitTreeContext.selectedUIKitComponent);
                }
              }}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Padding
          </label>
          <div className="flex flex-col mt-2 space-x-2">
            <input
              type="number"
              className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={useUIKitTreeContext.selectedUIKitComponent?.asUIKitContainer()?.paddingTop ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  useUIKitTreeContext.selectedUIKitComponent &&
                  useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                ) {
                  // TODO: Allow user to set padding for specific sides
                  useUIKitTreeContext.selectedUIKitComponent.paddingTop = parseInt(event.target.value);
                  useUIKitTreeContext.selectedUIKitComponent.paddingBottom = parseInt(event.target.value);
                  useUIKitTreeContext.selectedUIKitComponent.paddingLeft = parseInt(event.target.value);
                  useUIKitTreeContext.selectedUIKitComponent.paddingRight = parseInt(event.target.value);

                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ComponentProperties = (props: ComponentPropertiesProps) => {
  const uiKitComponentType = props.selectedUIKitComponent ? props.selectedUIKitComponent.type : undefined;

  const [tabs, setTabs] = useState([
    { name: "Display", href: "#", current: true },
    { name: "Layout", href: "#", current: false },
  ]);

  const baseLayoutPropertyControls: ReactNode[] = [
    <ControlBaseRowItemVerticalAlignment />,
    <ControlBaseColumnItemHorizontalAlignment />,
    <ControlBaseWidth />,
    <ControlBaseHeight />,
  ];

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      current: i === index,
    }));
    setTabs(updatedTabs);
  };

  const [isAddingEvent, setIsAddingEvent] = useState(false);

  const items = [
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    // More items...
  ];

  const containerPropertyControls = (): ReactNode[] => {
    let propertyControls: ReactNode[] = [];

    propertyControls.push(<GoToParentContainerLink />);

    for (const property in ContainerProperties) {
      switch (property) {
        case ContainerProperties.LayoutType.toString():
          {
            propertyControls.push(<ControlContainerLayoutType />);
          }
          break;
        case ContainerProperties.RowLayoutType.toString():
          {
            propertyControls.push(<ControlContainerRowAlignment />);
          }
          break;
        case ContainerProperties.ColumnLayoutType.toString(): {
          propertyControls.push(<ControlContainerColumnAlignment />);
        }
      }
    }

    propertyControls.push(<ContainerGapAndPaddingProperties></ContainerGapAndPaddingProperties>);

    return propertyControls;
  };

  const UIKitComponentPropertyControls = (type: UIKitComponentType): ReactNode[] => {
    let propertyControls: ReactNode[] = [];

    propertyControls.push(<ControlComponentName />);

    switch (type) {
      case UIKitComponentType.Button:
        {
          for (const property in ButtonProperties) {
            switch (property) {
              case ButtonProperties.Text.toString(): {
                propertyControls.push(<ControlBaseText />);
              }
            }
          }
        }
        break;

      case UIKitComponentType.Text:
        {
          for (const property in TextProperties) {
            switch (property) {
              case TextProperties.Text.toString(): {
                propertyControls.push(<ControlBaseText />);
              }
            }
          }
        }
        break;

      case UIKitComponentType.TextInput: {
        for (const property in TextProperties) {
          switch (property) {
            case TextProperties.Text.toString(): {
              propertyControls.push(<ControlBaseText />);
            }
          }
        }
      }
    }

    return propertyControls;
  };

  return (
    <>
      <div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-sky-500 text-sky-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={(event) => {
                    handleTabClick(index);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {tabs[0].current && uiKitComponentType && UIKitComponentPropertyControls(uiKitComponentType)}

      {tabs[1].current && uiKitComponentType && (
        <div className="flex mt-4 flex-col">
          <>
            {" "}
            {containerPropertyControls()} {baseLayoutPropertyControls}{" "}
          </>{" "}
        </div>
      )}
      {/* 
      {tabs[2].current && (
        <>
          <div className="w-full mt-2 flex space-y-2 flex-col">
            <label className="block text-sm font-medium leading-6 text-gray-900">On Load</label>
            <div className="flex mt-2">
              <button className="overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-center w-full">
                Add Event
              </button>
            </div>

            <label className="block text-sm font-medium leading-6 text-gray-900">On Click</label>
            <div className="flex mt-2">
              {!isAddingEvent && (
                <button
                  className="overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-center w-full"
                  onClick={() => {
                    setIsAddingEvent(true);
                  }}
                >
                  Add Event
                </button>
              )}

              {isAddingEvent && (
                <div className="flex flex-col overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-center w-full">
                  <div className="flex flex-col">
                    <button className="text-m pl-2 text-center hover:bg-gray-100" onClick={() => {}}>
                      Navigate to Screen
                    </button>
                    <button className="text-m pl-2 text-center hover:bg-gray-100" onClick={() => {}}>
                      Add a Document
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )} */}
    </>
  );
};

export default ComponentProperties;

import { UniqueIdentifier } from "@dnd-kit/core";
import {
  VerticalAlignment,
  HorizontalAlignment,
  UIKitContainerLayoutType,
} from "../components/Container";
import { PromptBlock } from "../DataEditor";

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export type UIKitComponentParams = {
  id: string;
  type: UIKitComponentType;
  name: string;
  parent?: UIKitComponent | undefined;
  children: UIKitComponent[];
  width: number;
  height: number;
  x: number;
  y: number;
  fixedWidth: boolean;
  fixedHeight: boolean;
  fitWidthToContent: boolean;
  fitHeightToContent: boolean;
  verticalAlign: VerticalAlignment;
  horizontalAlign: HorizontalAlignment;
  disableDragOverlay?: boolean;
  canDrag: boolean;
  canResize?: boolean;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  event?: Event;
  fillWidthToParent: boolean;
  fillHeightToParent: boolean;
};

export enum UIKitComponentType {
  AppView = "AppView",
  Container = "Container",
  Button = "Button",
  Text = "Text",
  TextInput = "TextInput",
  Chat = "Chat",
  List = "List",
}

export abstract class UIKitSerializable {
  abstract toJSONDocument(): any;
}

export class UIKitComponent extends UIKitSerializable {
  id: string;
  type: UIKitComponentType;
  name: string;
  parent?: UIKitComponent | undefined;
  children: UIKitComponent[];
  width: number;
  height: number;
  x: number;
  y: number;
  fixedWidth: boolean;
  fixedHeight: boolean;
  fitWidthToContent: boolean;
  fitHeightToContent: boolean;
  verticalAlign: VerticalAlignment;
  horizontalAlign: HorizontalAlignment;
  disableDragOverlay?: boolean;
  canDrag: boolean;
  canResize?: boolean;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  event?: Event;
  fillWidthToParent: boolean;
  fillHeightToParent: boolean;

  constructor(properties: UIKitComponentParams) {
    super();

    this.type = properties.type;
    this.id = properties.id;
    this.parent = properties.parent;
    this.name = properties.name;
    this.children = properties.children;
    this.verticalAlign = properties.verticalAlign;
    this.horizontalAlign = properties.horizontalAlign;
    this.disableDragOverlay = properties.disableDragOverlay;
    this.width = properties.width;
    this.height = properties.height;
    this.x = properties.x;
    this.y = properties.y;
    this.canDrag = properties.canDrag;
    this.canResize = properties.canResize;
    this.fixedWidth = properties.fixedWidth;
    this.fixedHeight = properties.fixedHeight;
    this.fitWidthToContent = properties.fitWidthToContent;
    this.minWidth = properties.minWidth
      ? properties.minWidth
      : properties.width;
    this.minHeight = properties.minHeight
      ? properties.minHeight
      : properties.height;
    this.maxWidth = properties.maxWidth;
    this.maxHeight = properties.maxHeight;
    this.fitHeightToContent = properties.fitWidthToContent;
    this.event = properties.event;
    this.fillWidthToParent = properties.fillWidthToParent;
    this.fillHeightToParent = properties.fillHeightToParent;
  }

  static findById(
    id: UniqueIdentifier,
    uiKitComponent: UIKitComponent
  ): UIKitComponent | undefined {
    let result: UIKitComponent | undefined = undefined;
    if (uiKitComponent.id === id) {
      return uiKitComponent;
    } else if (uiKitComponent.children) {
      uiKitComponent.children.some(
        (comp) => (result = this.findById(id, comp))
      );
    }

    return result;
  }
  

  static findByName(
    name: string,
    uiKitComponent: UIKitComponent
  ): UIKitComponent | undefined {
    let result: UIKitComponent | undefined = undefined;
    if (uiKitComponent.name === name) {
      return uiKitComponent;
    } else if (uiKitComponent.children) {
      uiKitComponent.children.some(
        (comp) => (result = this.findByName(name, comp))
      );
    }

    return result;
  }

  removeChild(childToRemove: UIKitComponent) {
    let index = this.children.indexOf(childToRemove);
    this.children.splice(index!, 1);
    childToRemove.parent = undefined;
  }

  appendChild(newChild: UIKitComponent) {
    newChild.parent = this;
    this.children.push(newChild);
  }

  previousSibling(): UIKitComponent | undefined {
    if (this.parent) {
      const children = this.parent.children;
      const currentIndex = children.indexOf(this);

      if (currentIndex >= 0) {
        return children[currentIndex - 1];
      }
    }

    return undefined;
  }

  nextSibling(): UIKitComponent | undefined {
    if (this.parent) {
      const children = this.parent.children;
      const currentIndex = children.indexOf(this);

      if (currentIndex >= 0) {
        return children[currentIndex + 1];
      }
    }

    return undefined;
  }

  insertBefore(
    newComponent: UIKitComponent,
    referenceComponent: UIKitComponent
  ) {
    if (this.parent) {
      const children = this.parent.children;
      const refIndex = children.indexOf(referenceComponent);

      if (refIndex !== -1) {
        children.splice(refIndex, 0, newComponent);
      }
    }
  }

  insertAfter(
    newComponent: UIKitComponent,
    referenceComponent: UIKitComponent
  ) {
    if (this.parent) {
      const children = this.parent.children;
      const refIndex = children.indexOf(referenceComponent);

      if (refIndex !== -1) {
        children.splice(refIndex + 1, 0, newComponent);
      }
    }
  }

  setFixedWidth() { 
    this.fixedWidth = true;
    this.fitWidthToContent = false;
    this.fillWidthToParent = false;
  }

  setFillWidth() { 
    this.fixedWidth = false;
    this.fitWidthToContent = false;
    this.fillWidthToParent = true;
  }

  setFitWidthToContent() { 
    this.fixedWidth = false;
    this.fitWidthToContent = true;
    this.fillWidthToParent = false;
  }

  setFixedHeight() { 
    this.fixedHeight = true;
    this.fitHeightToContent = false;
    this.fillHeightToParent = false;
  }

  setFillHeight() { 
    this.fixedHeight = false;
    this.fitHeightToContent = false;
    this.fillHeightToParent = true;
  }

  setFitHeightToContent() { 
    this.fixedHeight = false;
    this.fitHeightToContent = true;
    this.fillHeightToParent = false;
  }

  setWidth(width: number) {
    this.width = width;
  }

  setMinWidth(minWidth: number) {
    this.minWidth = minWidth;
  }

  setHeight(height: number) {
    this.height = height;
  }

  setMinHeight(minHeight: number) {
    this.minHeight = minHeight;
  }
  

  asUIKitContainer(): UIKitContainer | UIKitAppViewContainer | undefined {
    if (this instanceof UIKitAppViewContainer) {
      return this;
    }

    if (this instanceof UIKitContainer) {
      return this;
    }

    return undefined;
  }

  asUIKitAppViewContainer(): UIKitAppViewContainer | undefined {
    if (this instanceof UIKitAppViewContainer) {
      return this;
    }
    return undefined;
  }

  isAppViewContainer(): boolean {
    return this.type === UIKitComponentType.AppView;
  }

  isButton(): boolean {
    return this.type === UIKitComponentType.Button;
  }

  isText(): boolean {
    return this.type === UIKitComponentType.Text;
  }


  root(): UIKitComponent | undefined {
    let currentParent: UIKitComponent | undefined = this.parent;

    while (currentParent) {
      if (!currentParent.parent) return currentParent;
      currentParent = currentParent.parent;
    }
  }

  toJSONDocument() {
    let children: UIKitComponent[] = [];

    if (this.children) {
      this.children.forEach((child) => {
        children.push(child.toJSONDocument() as UIKitComponent);
      });
    }

    return {
      type: this.type,
      id: this.id,
      name: this.name,
      parent: {},
      children: children,
      verticalAlign: this.verticalAlign,
      horizontalAlign: this.horizontalAlign,
      disableDragOverlay: this.disableDragOverlay,
      width: this.width,
      height: this.height,
      x: this.x,
      y: this.y,
      canDrag: this.canDrag,
      canResize: this.canResize,
      fixedWidth: this.fixedWidth,
      fixedHeight: this.fixedHeight,
      minWidth: this.minWidth,
      minHeight: this.minHeight,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight,
      fitWidthToContent: this.fitWidthToContent,
      fitHeightToContent: this.fitHeightToContent,
      fillWidthToParent: this.fillWidthToParent,
      fillHeightToParent: this.fillHeightToParent
    };
  }
}

export class UIKitContainer extends UIKitComponent {
  layoutType: UIKitContainerLayoutType = UIKitContainerLayoutType.Row;
  rowLayoutType: HorizontalAlignment = HorizontalAlignment.LeftAligned;
  columnLayoutType: VerticalAlignment = VerticalAlignment.TopAligned;
  flexGap?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;

  constructor(
    properties: UIKitComponentParams,
    layoutType: UIKitContainerLayoutType,
    rowLayoutType: HorizontalAlignment,
    columnLayoutType: VerticalAlignment,
  ) {
    super(properties);
    this.layoutType = layoutType;
    this.rowLayoutType = rowLayoutType;
    this.columnLayoutType = columnLayoutType;
  }

  isFixedLayout(): boolean {
    return this.layoutType === UIKitContainerLayoutType.Fixed;
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
      layoutType: this.layoutType,
      rowLayoutType: this.rowLayoutType,
      columnLayoutType: this.columnLayoutType,
      flexGap: this.flexGap,
      paddingTop: this.paddingTop,
      paddingBottom: this.paddingBottom,
      paddingLeft: this.paddingLeft,
      paddingRight: this.paddingRight
    };
  }
}

export class UIKitAppViewContainer extends UIKitContainer {
  constructor(
    properties: UIKitComponentParams,
    layoutType: UIKitContainerLayoutType,
    rowLayoutType: HorizontalAlignment,
    columnLayoutType: VerticalAlignment
  ) {
    super(properties, layoutType, rowLayoutType, columnLayoutType);
    this.layoutType = layoutType;
    this.rowLayoutType = rowLayoutType;
    this.columnLayoutType = columnLayoutType;
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
      layoutType: this.layoutType,
      rowLayoutType: this.rowLayoutType,
      columnLayoutType: this.columnLayoutType,
      flexGap: this.flexGap,
      paddingTop: this.paddingTop,
      paddingBottom: this.paddingBottom,
      paddingLeft: this.paddingLeft,
      paddingRight: this.paddingRight
    };
  }
}
export class UIKitText extends UIKitComponent {
  text?: string;
  fontSize?: number
  textAlignment?: HorizontalAlignment;

  constructor(properties: UIKitComponentParams, text?: string, fontSize?: number, textAlignment?: HorizontalAlignment) {
    super(properties);
    this.text = text;
    this.fontSize = fontSize;
    this.textAlignment = textAlignment;
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
      text: this.text,
      fontSize: this.fontSize,
      textAlignment: this.textAlignment,
    };
  }
}

export class PromptBlockAction implements PromptBlock {
  id: string;
  promptValue: string;
  outputComponentId: string;
  triggerComponentId: string; 

  constructor(id: string, promptValue: string, outputComponentId: string, triggerComponentId: string) {
    this.id = id;
    this.promptValue = promptValue;
    this.outputComponentId = outputComponentId;
    this.triggerComponentId = triggerComponentId;
  }

  execute() { 
    this.interpolatePrompt();
  }

  createUserLlmMessage = async (interpolatedPrompt: string) => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/create_llm_message_for_user_app`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: interpolatedPrompt }),
    });

    return await response.json();
  };

  interpolatePrompt() {
    let interpolatedPrompt = this.promptValue;
    let matches = this.promptValue.match(/{{(.*?)}}/g);
    if (matches) {
      matches.forEach((match) => {
        let elementId = match.substring(2, match.length - 2);

        if (elementId) {
          let elementFromDom = document.getElementById(elementId) as HTMLInputElement;
          if (elementFromDom) {
            interpolatedPrompt = interpolatedPrompt.replace(match, `${elementFromDom.value}`);
          }
        }
      });
    }

    this.createUserLlmMessage(interpolatedPrompt).then((response) => {
      let outputElement = document.getElementById(this.outputComponentId) as HTMLInputElement;

      if (outputElement) {
        outputElement.value = response.message;
      }
    })



    return interpolatedPrompt;
  };
}

export class UIKitButton extends UIKitText {
  promptBlockAction?: PromptBlockAction;

  constructor(properties: UIKitComponentParams, text?: string, fontSize?: number) {
    super(properties);
    this.text = text;
    this.fontSize = fontSize;
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
      text: this.text,
    };
  }
}

export class UIKitTextInput extends UIKitText {
  placeholder?: string;
  multiLine?: boolean

  constructor(
    properties: UIKitComponentParams,
    text?: string,
    placeholder?: string,
    multiLine?: boolean
  ) {
    super(properties, text);
    this.placeholder = placeholder;
    this.multiLine = multiLine;
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
      placeholder: this.placeholder,
      multiLine: this.multiLine,
    };
  }
}

export class ChatComponent extends UIKitComponent {

  constructor(properties: UIKitComponentParams) {
    super(properties);
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
    };
  }
}

export class UIKitListItem extends UIKitComponent {
  label: string;

  constructor(properties: UIKitComponentParams, label: string) {
    super(properties);
    this.label = label
  }

  toJSONDocument() {
    return {
      ...super.toJSONDocument(),
      label: this.label,
    };
  }
}
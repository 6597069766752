import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { UIProps } from "./shared/Types";
import { UIKitComponent } from "./shared/UIKitComponent";
import ComponentProperties from "./ComponentProperties";

interface PropertiesWindowProps extends UIProps {
  selectedUIKitComponent: UIKitComponent | undefined;
  onClose: () => void;
  top: string;
  left: string;
}

const FloatingWindow: React.FC<PropertiesWindowProps> = (props: PropertiesWindowProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  return (
    <div
      style={{
        position: "absolute",
        top: props.top + "px",
        left: props.left + "px",
        transform: CSS.Translate.toString(transform),
        zIndex: "1",
      }}
    >
      <div {...attributes} {...listeners} ref={setNodeRef}>
        <div className="flex items-center justify-between px-4 py-3 bg-gray-200 rounded-t-lg">
          <h3 className="text-lg font-medium text-gray-800">
            {props.selectedUIKitComponent?.name} | {props.selectedUIKitComponent?.type} Element
          </h3>
          <button onMouseDown={props.onClose} className="text-gray-600 hover:text-gray-800">
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M6.707 6.293a1 1 0 0 1 1.414 0L12 10.586l3.88-3.88a1 1 0 0 1 1.414 1.414L13.414 12l3.88 3.88a1 1 0 0 1-1.414 1.414L12 13.414l-3.88 3.88a1 1 0 0 1-1.414-1.414L10.586 12 6.707 8.12a1 1 0 0 1 0-1.414z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="w-64 bg-white shadow-2xl rounded-lg" style={{ width: "450px" }}>
        <div className="p-4">
          <ComponentProperties selectedUIKitComponent={props.selectedUIKitComponent} />
        </div>
      </div>
    </div>
  );
};

export default FloatingWindow;

import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { NumberSize, Resizable } from "re-resizable";
import { Direction } from "re-resizable/lib/resizer";
import React, { CSSProperties, useEffect, useState } from "react";
import { UIKitMode } from "../shared/Types";
import { UIKitComponent, UIKitContainer } from "../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../UIKitTreeContext";
import { UIKitContainerLayoutType } from "./Container";

interface UIKitComponentOverlay {
  id: string;
  children: React.ReactNode;
  mode: UIKitMode;
  style?: CSSProperties;
}

export default function UIKitComponentOverlay(props: UIKitComponentOverlay) {
  let uiKitTreeContext: IUIKitTreeContext | null =
    React.useContext(UIKitTreeContext);

  let overlayedComponent: UIKitComponent = UIKitComponent.findById(
    props.id,
    uiKitTreeContext?.UIKitTree!
  )!;

  let { isDragging, attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: props.id,
      disabled: overlayedComponent
        ? overlayedComponent.disableDragOverlay
        : false,
    });

  const overlayStyle = {
    transform: CSS.Translate.toString(transform),
  };

  const updateSelectedUIKitComponent = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    uiKitTreeContext?.setSelectedUIKitComponentState(overlayedComponent);
    event.stopPropagation();
  };


  const updateResizableUIKitComponent = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    let elementId = (event.target as HTMLElement).id;

    deselectAllResizableComponents(uiKitTreeContext?.UIKitTree!);

    let currentParent: UIKitComponent | undefined = UIKitComponent.findById(
      elementId,
      uiKitTreeContext?.UIKitTree!
    )!.parent!;

    while (currentParent) {
      currentParent.disableDragOverlay = true;
      currentParent = currentParent.parent;
    }

    overlayedComponent.canResize = true;
    uiKitTreeContext?.updateUIKitTreeState();
    uiKitTreeContext?.updateComponentTree();

    event.stopPropagation();
  };

  function deselectAllResizableComponents(
    uiKitComponent: UIKitComponent
  ): UIKitComponent | undefined {
    let result: UIKitComponent | undefined = undefined;

    uiKitComponent.canResize = false;

    if (uiKitComponent.parent) {
      uiKitComponent.disableDragOverlay = false;
    }

    uiKitComponent.children.some(
      (comp) => (result = deselectAllResizableComponents(comp))
    );
    return result;
  }

  const [clicked, setClicked] = useState(false);
    const [points, setPoints] = useState({
      x: 0,
      y: 0,
    });

    const decreaseSelectedUIKitComponentNodeIndex = () => {
      let oldIndex = overlayedComponent?.parent?.children.indexOf(
        overlayedComponent
      );
  
      if (!(oldIndex! <= 0) && overlayedComponent) {
        overlayedComponent?.insertBefore(
          overlayedComponent!,
          overlayedComponent!.previousSibling()!
        );
        overlayedComponent?.parent?.children.splice(oldIndex! + 1, 1);
  
        uiKitTreeContext?.updateUIKitTreeState();
        uiKitTreeContext?.updateComponentTree();
      }
    };
  
    const increaseSelectedUIKitComponentNodeIndex = () => {
      let oldIndex = overlayedComponent?.parent?.children.indexOf(
        overlayedComponent
      );
  
      if (!(oldIndex! >= overlayedComponent?.parent?.children.length! - 1) && uiKitTreeContext?.selectedUIKitComponent) {
        overlayedComponent?.insertAfter(
          overlayedComponent!,
          overlayedComponent!.nextSibling()!
        );
  
        overlayedComponent?.parent?.children.splice(oldIndex!, 1);
  
        uiKitTreeContext?.updateUIKitTreeState();
        uiKitTreeContext?.updateComponentTree();
      }
    };
  
    const deleteSelectedUIKitComponentNode = () => {
      overlayedComponent?.parent?.removeChild(overlayedComponent!);
      uiKitTreeContext?.setSelectedUIKitComponentState(undefined);
      uiKitTreeContext?.updateComponentTree();
    };


  return (
    <>
    {clicked && ( 
            <div style={{position: "absolute", top: points.y, left: points.x}}>
                          <div className="flex flex-col overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-left w-full">
                  <div className="flex flex-col">
                    <button className="text-m text-left hover:bg-gray-100" onMouseDown={(event) => {
                       setClicked(false);
                       deleteSelectedUIKitComponentNode();
                    }}>
                      Delete
                    </button>
{/* {                     <button className="text-m text-left  hover:bg-gray-100" onMouseDown={(event) => {
                      setClicked(false);
                      console.log(overlayedComponent)
                    }}>
                      Log selected component
                    </button>} */}
                    <button className="text-m text-left hover:bg-gray-100" onMouseDown={(event) => {
                      setClicked(false);
                      decreaseSelectedUIKitComponentNodeIndex();
                    }}>
                      Move up
                    </button>
                    <button className="text-m text-left hover:bg-gray-100" onMouseDown={(event) => {
                      setClicked(false);
                      increaseSelectedUIKitComponentNodeIndex();
                    }}>
                      Move down
                    </button>
                  </div>
                </div>
        </div>)}
    
      {overlayedComponent && overlayedComponent.canResize && (
        <Resizable
          className={"border border-sky-500"}
          size={{
            width: overlayedComponent.width,
            height: overlayedComponent.height,
          }}
          style={{ ...props.style, ...overlayStyle }}
          onResizeStop={(
            event: MouseEvent | TouchEvent,
            direction: Direction,
            elementRef: HTMLElement,
            delta: NumberSize
          ) => {
            overlayedComponent.width = overlayedComponent.width + delta.width;
            overlayedComponent.height =
              overlayedComponent.height + delta.height;
            uiKitTreeContext?.updateUIKitTreeState();
            uiKitTreeContext?.updateComponentTree();
          }}
        >
          <div
            ref={setNodeRef}
            id={props.id}
            {...listeners}
            {...attributes}
            style={{
              ...props.style,
              ...{ top: 0, left: 0 },
            }}
            onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
              if (event.detail === 2) {
                updateSelectedUIKitComponent(event);
              }
            }}
          >
            {props.children}
          </div>
        </Resizable>
      )}

      
      

      {overlayedComponent &&
        !overlayedComponent.canResize &&
        props.mode == UIKitMode.Edit && (
          <div
            ref={setNodeRef}
            className={`hover:border hover:border-blue-300`}
            id={props.id}
            {...listeners}
            {...attributes}
            onContextMenu={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setClicked(true);
              setPoints({
                x: e.pageX,
                y: e.pageY,
              });
            }}
            style={{
              ...props.style,
              ...overlayStyle,
            }}
            onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
              if (event.detail === 2) {
                updateSelectedUIKitComponent(event);
              }

              if (
                overlayedComponent.parent
                  ?.asUIKitAppViewContainer()
                  ?.isFixedLayout() ||
                overlayedComponent.parent?.asUIKitContainer()?.isFixedLayout()
              ) {
                updateResizableUIKitComponent(event);
              } else if (
                overlayedComponent?.asUIKitAppViewContainer()?.isFixedLayout()
              ) {
                deselectAllResizableComponents(overlayedComponent);
              }
            }}
          >
            {props.children}
          </div>
        )}
    </>
  );
}

import { DndContext, DragEndEvent, DragMoveEvent, rectIntersection } from "@dnd-kit/core";
import { ToolBoxItemProps, UIKitMode } from "./shared/Types";
import { UIKit } from "./utilities/UIKit";
import { CSSProperties, Fragment, ReactNode, useEffect, useRef, useState } from "react";
import React from "react";
import FloatingWindow from "./FloatingWindow";
import { Coordinates } from "@dnd-kit/utilities";
import { UIKitComponent } from "./shared/UIKitComponent";
import { IUIKitTreeContext, ModalType, UIKitTreeContext } from "./UIKitTreeContext";
import ToolboxItem from "./ToolboxItem";
import { AppWizardModal } from "./AppWizardModal";
import { useNavigate, useParams } from "react-router-dom";
import { EditorNavigation } from "./EditorNavigation";
import { useMutation } from "@tanstack/react-query";
import { Dialog, Transition } from "@headlessui/react";

interface UIEditorProps {
  mode: UIKitMode;
  appEnvironmentId?: number;
  showAppWizardModal?: boolean;
  showAppListModal?: boolean;
}

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export default function UIEditor(props: UIEditorProps) {
  let uiKitTreeContext: IUIKitTreeContext | null = React.useContext(UIKitTreeContext);
  let [uiKitReactTree, setUIKitReactTree] = useState<ReactNode>();
  const [editorWidth, setEditorWidth] = useState(window.innerWidth);
  const [editorHeight, setEditorHeight] = useState(window.innerHeight);
  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => setEditorWidth(window.innerWidth));
    window.addEventListener("resize", () => setEditorHeight(window.innerHeight));

    if (params.id) {
      uiKitTreeContext?.loadAppEnvironmentFromNetwork(params.id);

      if (uiKitTreeContext?.isLoggedOut) {
        navigate("/login");
      }

      if (uiKitTreeContext?.UIKitTree) {
        setUIKitReactTree(UIKit.createReactNodeTreeFromUIKitComponent(uiKitTreeContext?.UIKitTree, props.mode));
      }
    }
    if (uiKitTreeContext?.selectedUIKitComponent) {
      handleFloatingWindowOpen();
    }
  }, [uiKitTreeContext?.selectedUIKitComponent, uiKitTreeContext?.UIKitTree, uiKitTreeContext?.isLoggedOut]);

  const toolBoxItems: ToolBoxItemProps[] = [
    { id: "button-tool", itemName: "Button", icon: undefined },
    { id: "container-tool", itemName: "Container", icon: undefined },
    { id: "text-tool", itemName: "Text", icon: undefined },
    { id: "textInput-tool", itemName: "Text Input", icon: undefined },
  ];

  const [showSelectedComponentPropertiesWindow, setShowFloatingWindow] = useState<boolean>(false);

  const handleFloatingWindowClose = () => {
    uiKitTreeContext?.setSelectedUIKitComponentState(undefined);
    setShowFloatingWindow(false);
  };

  const handleFloatingWindowOpen = () => {
    setShowFloatingWindow(true);
  };

  const floatingWindowDefaultCoordinates = {
    x: 0,
    y: 0,
  };

  const [{ x, y }, setFloatingWindowCoordinates] = useState<Coordinates>(floatingWindowDefaultCoordinates);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const generateAppEnvironmentLiveURL = (): string => {
    const appLiveURLName = uiKitTreeContext?.appEnvironment?.name.toLowerCase().replace(/\s+/g, "-");
    const uniqueNumber = Math.floor(1000 + Math.random() * 9000);

    const finalAppLiveURL = `${appLiveURLName}-${uniqueNumber}`;

    return finalAppLiveURL;
  };

  const updateAppEnvironmentLiveURLRequest = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/app_environment/${uiKitTreeContext?.appEnvironment?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
        Accept: "application/json",
      },
      body: JSON.stringify({ live_url: generateAppEnvironmentLiveURL() }),
    });

    if (!response.ok && response.status == 401) {
      // Logout the user if they're unauthorized
      uiKitTreeContext?.setIsLoggedOut(true);
    }

    return await response.json();
  };

  const updateAppEnvironmentLiveURLMutation = useMutation({
    mutationFn: updateAppEnvironmentLiveURLRequest,
    onSuccess: (response) => {
      let currentAppEnvironment = uiKitTreeContext?.appEnvironment;

      if (currentAppEnvironment) {
        currentAppEnvironment.liveURL = response.live_url;
        uiKitTreeContext?.loadAppEnvironmentFromObject(currentAppEnvironment);
        uiKitTreeContext?.setCurrentModalState(ModalType.ShareAppModal);
      }
    },
  });

  return (
    <>
      <DndContext
        onDragEnd={(event: DragEndEvent) => {
          setFloatingWindowCoordinates(({ x, y }) => {
            return {
              x: x + event!.delta.x,
              y: y + event!.delta.y,
            };
          });
        }}
      >
        {showSelectedComponentPropertiesWindow && uiKitTreeContext.selectedUIKitComponent != undefined && (
          <FloatingWindow
            id="properties-window"
            mode={UIKitMode.None}
            top={y.toString()}
            left={x.toString()}
            onClose={handleFloatingWindowClose}
            selectedUIKitComponent={uiKitTreeContext?.selectedUIKitComponent}
          />
        )}

        {uiKitTreeContext.currentModal === ModalType.ShareAppModal && <ShareAppModal></ShareAppModal>}

        <DndContext
          collisionDetection={rectIntersection}
          onDragEnd={(event: DragEndEvent) => {
            if (!uiKitTreeContext!.UIKitTree) return;

            let currentTarget: UIKitComponent | undefined = UIKitComponent.findById(
              event.active!.id,
              uiKitTreeContext!.UIKitTree
            );
            let newTarget: UIKitComponent | undefined = UIKitComponent.findById(
              event.over!.id,
              uiKitTreeContext!.UIKitTree
            );

            if (event.active!.id != event.over!.id) {
              if (currentTarget && currentTarget.parent && newTarget) {
                if (currentTarget.parent.id != newTarget?.id) {
                  currentTarget?.parent?.removeChild(currentTarget);
                  newTarget?.appendChild(currentTarget!);
                  uiKitTreeContext?.updateComponentTree();
                }
              } else if (newTarget) {
                let uiKitComponentTypeFromDragEndEvent = event.active!.id.toString().split("-")[0];
                let newComponent = UIKit.createUIKitComponent(
                  uiKitComponentTypeFromDragEndEvent,
                  newTarget,
                  event.delta.x,
                  event.delta.y
                );
                if (newComponent) {
                  // Add the component to the tree
                  newTarget?.appendChild(newComponent);
                  uiKitTreeContext?.updateComponentTree();
                }
              }
            }
            setUIKitReactTree(UIKit.createReactNodeTreeFromUIKitComponent(uiKitTreeContext!.UIKitTree, UIKitMode.Edit));
          }}
        >
          <EditorNavigation
            appName={uiKitTreeContext?.appEnvironment?.name}
            userId={uiKitTreeContext.currentUser?.id ?? "Unknown name"}
            showAppWizardModal={props.showAppWizardModal ?? false}
            showAppListModal={props.showAppListModal}
          />
          <div
            className="flex flex-row bg-gray-100 p-8 gap-x-4"
            style={{ minWidth: `${(editorWidth / 100) * 100}px`, minHeight: `${(editorHeight / 100) * 95}px` }}
          >
            <nav
              className="flex flex-row pl-8 pr-8 pt-4 shadow-xl rounded-xl ring-1 ring-gray-100 bg-white"
              aria-label="Sidebar"
            >
              <ul role="list" className="flex flex-col gap-y-7 w-full">
                <li>
                  <ul role="list" className="space-y-1 w-full">
                    <li className="font-bold">Components</li>
                    <div className="flex-grow border-t border-gray-400 pb-4"></div>
                    {toolBoxItems.map((item) => (
                      <li key={item.itemName}>
                        <ToolboxItem {...item} />
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
            <div style={{ width: "100%" }} className="flex flex-col">
              <div className="flex flex-row justify-end mb-2 space-x-4">
                <label className="text-lg font-bold self-center" style={{ marginRight: "auto" }}>
                  {uiKitTreeContext.appEnvironment?.name}
                </label>
                {/*                       <button
                        type="button"
                        className="w-18 inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          uiKitTreeContext?.setCurrentModalState(ModalType.UserFeatureRequestModal)
                        }}
                      >
                        Submit Feature Request
                      </button>
                      <button
                        type="button"
                        className="w-18 inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          uiKitTreeContext?.setCurrentModalState(ModalType.UserFeedbackModal)
                        }}
                      >
                        Submit Feedback
                      </button> */}
                <button
                  type="button"
                  className="w-18 inline-flex items-center rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    updateAppEnvironmentLiveURLMutation.mutate();
                  }}
                >
                  Share Your App
                </button>
              </div>
              {uiKitReactTree}
            </div>
          </div>
        </DndContext>
      </DndContext>
    </>
  );
}

export const ShareAppModal = () => {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const [feedbackContent, setFeedbackContent] = useState("");
  const useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-100 bg-opacity-80 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-bold leading-6 text-gray-900 mb-2">
                        Your app is now live 🚀
                      </Dialog.Title>
                    </div>
                  </div>
                  <label className="block text-md font-normal leading-6 text-gray-900">
                    Share your app using the URL below:
                    <br></br>
                    <b>
                      <u>
                        <a href={`https://www.buildify.cloud/${useUIKitTreeContext.appEnvironment?.liveURL}`}>
                          https://www.buildify.cloud/{useUIKitTreeContext.appEnvironment?.liveURL}
                        </a>
                      </u>
                    </b>
                  </label>

                  <div className="flex flex-row w-full justify-end gap-x-2">
                    <button
                      type="button"
                      className="w-18 inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setOpen(false);
                        useUIKitTreeContext.setCurrentModalState(undefined);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

enum FeedackType {
  FeatureRequest = "FeatureRequest",
  Feedback = "Feedback",
}

export const SubmitUserFeedbackModa = (type: FeedackType) => {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const [feedbackContent, setFeedbackContent] = useState("");
  const useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  const createUserFeedback = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/user_feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
      },
      body: JSON.stringify({
        user_email: useUIKitTreeContext?.currentUser?.email,
        type: type,
        feedbackContent: feedbackContent,
      }),
    });

    return await response.json();
  };

  const createUserFeedbackMutation = useMutation({
    mutationFn: createUserFeedback,
    onSuccess: () => {
      alert("Thank you for your feedback!");
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-100 bg-opacity-80 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-2 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-bold leading-6 text-gray-900">
                        {type === FeedackType.FeatureRequest ? "Feature Request" : "Feedback"}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="block text-md font-normal leading-6 text-gray-900">
                      {type === FeedackType.FeatureRequest ? "Submit your feature request" : "Submit your feedback"}
                    </label>
                    <div className="flex mt-2 w-full">
                      <textarea
                        rows={8}
                        className="w-full mb-4 rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
                        onChange={(e) => setFeedbackContent(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex flex-row w-full justify-end gap-x-2">
                    <button
                      type="button"
                      className="w-18 inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setOpen(false);
                        useUIKitTreeContext.setCurrentModalState(undefined);
                      }}
                    >
                      Submit Feedback
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import React, { ReactElement } from "react";
import DropdownMenu from "../../../DropdownMenu";
import { UIKitMode } from "../../../shared/Types";
import {
  UIKitContainer,
} from "../../../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";
import { UIKitContainerLayoutType } from "../../Container";

const ControlContainerLayoutType = (): ReactElement => {
  let useUIKitTreeContext: IUIKitTreeContext =
    React.useContext(UIKitTreeContext);

  const containerLayoutTypes: string[] = Object.values(
    UIKitContainerLayoutType
  );

  if (useUIKitTreeContext.selectedUIKitComponent) {
    return (

      <>
      {useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer &&  (
          <div className="w-full">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Container Type
            </label>
            <div className="flex mt-2">
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className={"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                + (useUIKitTreeContext.selectedUIKitComponent.layoutType == UIKitContainerLayoutType.Row ? " bg-gray-100" : "bg-white")}
                onClick={() => {
                  if (
                    useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                  ) {
                    useUIKitTreeContext.selectedUIKitComponent.layoutType = UIKitContainerLayoutType.Row;
                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Row
              </button>
              <button
                type="button"
                className={"relative -ml-px inline-flex  rounded-r-md items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                + (useUIKitTreeContext.selectedUIKitComponent.layoutType == UIKitContainerLayoutType.Column ? " bg-gray-100" : "bg-white") }
                onClick={() => {
                  if (
                    useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                  ) {
                    useUIKitTreeContext.selectedUIKitComponent.layoutType = UIKitContainerLayoutType.Column
                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Column
              </button>
            </span>
            </div>
          </div>
      )}
    </>

    );
  }
  return <></>;
};

export default ControlContainerLayoutType;
